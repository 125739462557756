import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router) {
  }

  /**
   * Intercepts the Http request and handles any errors that occur during the request.
   *
   * @param {HttpRequest<any>} request - The Http request to be intercepted.
   * @param {HttpHandler} next - The HttpHandler that handles the request.
   * @returns {Observable<HttpEvent<any>>} - The Observable that represents the Http event.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            sessionStorage.clear();
            this.router.navigate(['/']);
          }
          const error = err.error?.message || err.status;
          return throwError(() => err);
          // this.handleErrors(err);
        }
      )
    );
  }

  /**
   * Handle errors from HTTP requests.
   *
   * @param {HttpErrorResponse} err - The error response from the HTTP request.
   * @returns {Observable<never>} - An observable that emits the error message or status.
   */
  private handleErrors(err: HttpErrorResponse) {
    const error = err.error?.message || err.status;
    return throwError(error);
  }
}
