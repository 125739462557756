import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {JWT_OPTIONS, JwtModule} from '@auth0/angular-jwt';

export const jwtOptionsFactory = () => ({
  tokenGetter: () => {
    return sessionStorage.getItem("access_token");
  },
  disallowedRoutes: [
    // add all public api endpoints here.
    '/dashboard-admin-gateway/oauth2/initiate',
    '/dashboard-admin-gateway/oauth2/token',
    '/dashboard-admin-gateway/zipCode',
    '/dashboard-admin-gateway/organizations',
    '/dashboard-admin-gateway/oauth/sendVerifyOtp',
    '/dashboard-admin-gateway/oauth/verifyOtp',
    '/dashboard-admin-gateway/users/',
    '/dashboard-admin-gateway/users/organization',
    '/dashboard-admin-gateway/oauth/googleSignIn',
    '/dashboard-admin-gateway/oauth/facebookSignIn',
    '/dashboard-admin-gateway/impersonate/token'
  ]
});

@NgModule({
  imports:
    [
      CommonModule,
      JwtModule.forRoot({
        jwtOptionsProvider: {
          provide: JWT_OPTIONS,
          useFactory: jwtOptionsFactory,
        }
      })
    ],
  providers: [],
})
export class SecurityModule {
}
